import revive_payload_client_3k6Esuh9Xr from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1tIR9sfNMw from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Z9bMiIULU2 from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_k9o3nNhbeS from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_WDbVwlLole from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_O0Osqf9mL2 from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lzwTfTdvE8 from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_kde42vevy3 from "/Users/runner/work/1/s/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.30.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/runner/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_i7gJEGAqSx from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_zoF4yPIBkC from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt-socket-io@3.0.13/node_modules/nuxt-socket-io/lib/plugin.js";
import plugin_cLtIOlkYge from "/Users/runner/work/1/s/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.5.13_typescript@5.6.3_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_ZnrIGiNKUt from "/Users/runner/work/1/s/node_modules/.pnpm/pinia-plugin-persistedstate@4.0.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.30.1_typescript@5_uavmw4epfdbheglsie5hfrzfnm/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_fTJRdDSkda from "/Users/runner/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_mb5dUDtqMc from "/Users/runner/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_eEjuZ18Uji from "/Users/runner/work/1/s/node_modules/.pnpm/@nuxt+icon@1.6.1_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_terse_gqcjvwgk7zoqnprcalbabqq4qi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import axios_QMFgzss1s4 from "/Users/runner/work/1/s/plugins/axios.ts";
import global_variables_9gxCQlMwlK from "/Users/runner/work/1/s/plugins/global-variables.ts";
import maska_UHaKf2z1iQ from "/Users/runner/work/1/s/plugins/maska.ts";
import socket_io_client_oNh7qdoRBj from "/Users/runner/work/1/s/plugins/socket.io.client.ts";
import vue3_popper_YyL3RO5Qbz from "/Users/runner/work/1/s/plugins/vue3-popper.ts";
export default [
  revive_payload_client_3k6Esuh9Xr,
  unhead_1tIR9sfNMw,
  router_Z9bMiIULU2,
  payload_client_k9o3nNhbeS,
  navigation_repaint_client_WDbVwlLole,
  check_outdated_build_client_O0Osqf9mL2,
  chunk_reload_client_lzwTfTdvE8,
  plugin_vue3_kde42vevy3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_i7gJEGAqSx,
  plugin_zoF4yPIBkC,
  plugin_cLtIOlkYge,
  plugin_ZnrIGiNKUt,
  switch_locale_path_ssr_fTJRdDSkda,
  i18n_mb5dUDtqMc,
  plugin_eEjuZ18Uji,
  axios_QMFgzss1s4,
  global_variables_9gxCQlMwlK,
  maska_UHaKf2z1iQ,
  socket_io_client_oNh7qdoRBj,
  vue3_popper_YyL3RO5Qbz
]