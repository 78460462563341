import routerOptions0 from "/Users/runner/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_4dwwkfnxy6bhgkgkoto5nu7hta/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  linkExactActiveClass: "active",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}