<script setup lang="ts">
import type { Sale } from '@/types/graphics.js'
import VueApexCharts from 'vue3-apexcharts'

const props = defineProps<{
  salesData: Sale[]
  salesByCategorySeries: number[]
  monthRef: number
  allSalesAreZero: boolean
}>()

const emits = defineEmits(['handleLoadSalesPerMonth'])

const store = useAppStore()

const selectedOption = ref(new Date().getMonth() + 1)

const options = ref([
  { value: 1, label: 'Ene' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Abr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Ago' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dic' },
])

// sales by category
const salesByCategory = computed(() => {
  const isDark = !!(store.theme === 'dark' || store.isDarkMode)
  return {
    chart: {
      type: 'donut',
      height: 460,
      fontFamily: 'Poppins, sans-serif',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 25,
      colors: isDark ? '#0e1726' : '#fff',
    },
    colors: isDark ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : ['#e2a03f', '#5c1ac3', '#e7515a'],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      markers: {
        width: 10,
        height: 10,
        offsetX: -2,
      },
      height: 50,
      offsetY: 20,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '29px',
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: '26px',
              color: isDark ? '#bfc9d4' : undefined,
              offsetY: 16,
              formatter: (val: unknown) => {
                return val
              },
            },
            total: {
              show: true,
              label: 'Total',
              color: '#888ea8',
              fontSize: '29px',
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              },
            },
          },
        },
      },
    },
    labels: props.salesData.map(sale => sale.name),
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 0.15,
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0.15,
        },
      },
    },
  }
})
</script>

<template>
  <section>
    <div class="mb-5 flex items-center justify-between bg-white rounded dark:bg-dark-light shadow p-2">
      <div>
        <div class="mb-1.5">
          <label
            for="validationDate"
            class="block text-zinc-700 text-sm font-bold dark:text-zinc-300 mb-1"
          >
            Selecciona el mes
          </label>
          <div class="form-container mb-2 relative">
            <select
              v-model="selectedOption"
              class="form-input-v2"
              @change="emits('handleLoadSalesPerMonth', ($event.target as HTMLSelectElement).value)"
            >
              <option
                v-for="option in options"
                :key="option.label"
                :value="option.value"
                :selected="option.value === monthRef"
              >
                {{ option.label }}
              </option>
            </select>
            <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <IconCaretDown />
            </div>
          </div>
        </div>
        <small class="font-semibold">
          ¡Hola! 👋 Elige el mes que quieras y te mostraré los productos más exitosos de ese período.
        </small>
      </div>
      <UtilitiesDownloadCharts
        :sales-by-category-series="salesByCategorySeries"
        :revenue-series="[]"
        chart-id="chart"
      />
    </div>
    <div
      v-if="allSalesAreZero"
      id="chart"
      class="text-center text-lg text-gray-500 dark:text-white-light h-[300px] flex items-center justify-center p-3 bg-white dark:bg-dark-light rounded"
    >
      <h4 class="font-bold">
        No hay datos que mostrar.
      </h4>
    </div>
    <div v-else>
      <VueApexCharts
        id="chart"
        height="460"
        :options="salesByCategory"
        :series="salesByCategorySeries"
        class="overflow-hidden p-3 py-6 bg-white dark:bg-dark-light rounded"
      />
    </div>
  </section>
</template>
