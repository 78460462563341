import { defineAsyncComponent } from 'vue'
export default {
  auth: defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/auth.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/default.vue").then(m => m.default || m)),
  "places-menu": defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/places-menu.vue").then(m => m.default || m)),
  pos: defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/pos.vue").then(m => m.default || m)),
  "sidebar-invoice-menu": defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/sidebar-invoice-menu.vue").then(m => m.default || m)),
  "sidebar-marketplace-menu": defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/sidebar-marketplace-menu.vue").then(m => m.default || m)),
  "sidebar-module-menu": defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/sidebar-module-menu.vue").then(m => m.default || m)),
  "sidebar-place-menu": defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/sidebar-place-menu.vue").then(m => m.default || m)),
  "sidebar-runner-menu": defineAsyncComponent(() => import("/Users/runner/work/1/s/layouts/sidebar-runner-menu.vue").then(m => m.default || m))
}